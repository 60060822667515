/* eslint-disable */
import { COUNTRY_CODES, BOOK_TYPE } from "./constants";

export const KU_RATE = 0.004;


export const BOOK_FORMATS = [
  { name: "eBook", code: BOOK_TYPE.EBOOK },
  { name: "Paperback - Standard", code: BOOK_TYPE.PAPERBACK_STANDARD },
]

const ROYALTY_FORMAT_MAP = {
  [BOOK_TYPE.EBOOK]: [0.35, 0.7],
  [BOOK_TYPE.PAPERBACK_EXTENDED]: [0.4, 0.4],
  [BOOK_TYPE.PAPERBACK_STANDARD]: [0.6, 0.6]
}
export const COUNTRY_MAP = [
  {
    name: "United States", code: COUNTRY_CODES.USA
  },
  {
    name: "Canada", code: COUNTRY_CODES.CANADA
  },
  {
    name: "United Kingdom", code: COUNTRY_CODES.UNITED_KINGDOM,
  },
  {
    name: "France", code: COUNTRY_CODES.FRANCE,
  },
  {
    name: "Spain", code: COUNTRY_CODES.SPAIN,
  },
  {
    name: "Germany", code: COUNTRY_CODES.GERMANY
  },
  {
    name: "Netherlands", code: COUNTRY_CODES.NETHERLANDS
  },
  {
    name: "Poland", code: COUNTRY_CODES.POLAND
  },
  {
    name: "Belgium", code: COUNTRY_CODES.BELGIUM
  },
  {
    name: "Italy", code: COUNTRY_CODES.ITALY
  },
];

export const getRoyaltyForCountry = (countryCode) => {
  switch (countryCode) {
    case COUNTRY_CODES.USA:
    case COUNTRY_CODES.CANADA:
      return [2.99, 9.99];
    case COUNTRY_CODES.UNITED_KINGDOM:
      return [1.77, 9.99];
    default:
      return [2.69, 9.99];
  }
}

export const calculateRMandNRR = (books, type, countryCode, formatCode) => {
  const ebook_calcs = books.reduce((hist, current, index) => {
    const [lowerRoyaltyBoundary, higherRoyaltyBoundary] = getRoyaltyForCountry(countryCode);
    const [lowerRoyaltyRate, higherRoyaltyRate] = ROYALTY_FORMAT_MAP[formatCode]
    const royalty = (current.sale_price > higherRoyaltyBoundary || current.sale_price < lowerRoyaltyBoundary) ? lowerRoyaltyRate : higherRoyaltyRate;

    // NRR
    const net_royalties_per_sale = formatCode === BOOK_TYPE.EBOOK ? (current.sale_price - current.delivery_cost) * royalty : (royalty * current.sale_price) - current.delivery_cost;
    const total_sales = current.period_orders * current.sale_price;

    // RM
    const net_borrow = KU_RATE;
    const period_borrows = current.period_total_reads;

    const borrows_rt = index === 0 ? 1
      : hist[0]?.period_borrows ? period_borrows / hist[0].period_borrows : 0;
    const book1_borrow_rate = net_borrow * borrows_rt;
    const ratio_sales_to_borrow = period_borrows / current.period_orders;

    const sales_rt = index === 0 ? 1 :
      hist[0]?.period_orders ? current.period_orders / hist[0].period_orders : 1;

    const book_sale_value = net_royalties_per_sale * sales_rt;


    return [...hist, {
      ...current,
      net_royalties_per_sale,
      net_royalties_earned: net_royalties_per_sale * current.period_orders,
      total_sales,

      net_borrow,
      period_borrows,
      borrows_rt,
      book1_borrow_rate,
      ratio_sales_to_borrow,
      sales_rt,
      book_sale_value,
    }];
  }, []);

  const total_book_sale_value = ebook_calcs.reduce((acc, current) => acc + current.book_sale_value, 0);
  const total_book1_borrow_value = ebook_calcs.reduce((acc, current) => acc + current.book1_borrow_rate, 0);
  const RM1 = Math.max(0,
    (total_book_sale_value + (ebook_calcs[0].ratio_sales_to_borrow * total_book1_borrow_value))
    / (ebook_calcs[0].book_sale_value + (ebook_calcs[0].ratio_sales_to_borrow * ebook_calcs[0].book1_borrow_rate))
  );

  const total_sales = ebook_calcs.reduce((acc, current) => acc + current.total_sales, 0);
  const total_royalties_earned = ebook_calcs.reduce((acc, current) => acc + current.net_royalties_earned, 0);

  const totalKENPCPages = books.reduce((acc, next) => acc + next.period_total_reads, 0);
  const averageKENPC = books.reduce((acc, next) => acc + next.KENPC * (next.period_total_reads / totalKENPCPages), 0);

  return type === 'single-book' ? {
    RM: (formatCode === BOOK_TYPE.EBOOK ? RM1 : Math.max(0, total_book_sale_value / ebook_calcs[0].book_sale_value)).toFixed(2),
    NRR: (Math.max(0, ebook_calcs[0].net_royalties_earned / ebook_calcs[0].total_sales) * 100).toFixed(0),
    averageKENPC: Math.max(0, books[0].KENPC).toFixed(2),
  } : {
    RM: 1,
    NRR: (Math.max(0, total_royalties_earned / total_sales) * 100).toFixed(0),
    averageKENPC: (Math.max(0, averageKENPC)).toFixed(2)
  };
};
