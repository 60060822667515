<template>
  <div
    v-if="loading"
    class="text-center"
  >
    <ProgressSpinner />
  </div>
  <template v-else>
    <div class="flex py-5 align-items-center">
      <div class="flex-item text-left">
        <p>
          The metrics included in this section will apply to <strong>{{ selectedProfile.name }} - {{ selectedProfile.countryCode }}</strong>.
        </p>
      </div>
    </div>

    <div class="pb-5">
      <MetricCreation
        :selected-profile="selectedProfile"
        :campaigns="campaigns"
        @createMetric="createMetricHandler"
      />
    </div>
    <MetricListing
      :selected-profile="selectedProfile"
      :campaigns="campaigns"
      :metrics="metrics"
      @deleteMetric="deleteMetricHandler"
    />
  </template>
</template>

<script>

import A2CAClient from '../../../api/a2caAuthorizedClient';
import MetricCreation from './metrics/MetricCreation.vue';
import MetricListing from './metrics/list/MetricListing.vue';

export default {
  components: { MetricCreation, MetricListing },
  inject: ['query'],
  props: {
    selectedProfile: {
      type: Object,
      required: true,
    },
  },
  emits: ['clear-selected-profile', 'change-menu-layout'],
  data() {
    return {
      campaigns: null,
      metrics: null,
      loading: false,
    };
  },
  async created() {
    this.loading = true;
    const client = new A2CAClient(this.query);
    const campaignsRequest = client.getCampaigns(
      this.selectedProfile.profileId,
      this.selectedProfile.region,
      'ENABLED',
    );

    const metricsRequest = client.getProfileMetrics(this.selectedProfile.profileId);

    const [campaigns, metrics] = await Promise.all([campaignsRequest, metricsRequest]);
    this.campaigns = campaigns;
    this.metrics = metrics;
    this.loading = false;
  },
  methods: {
    createMetricHandler(createdMetric) {
      this.metrics.push(createdMetric);
      this.$store.commit('setMetrics', [...this.metrics]);
    },
    deleteMetricHandler(metricId) {
      this.metrics = this.metrics.filter((metric) => metric.id !== metricId);
      this.$store.commit('setMetrics', [...this.metrics]);
    },
  },
};
</script>
