<template>
  <Card>
    <template #title>
      <h2
        ref="scrollRef"
        class="text-2xl"
      >
        Metric Creation
      </h2>
    </template>
    <template #content>
      <MetricForm
        :editable="true"
        :initial-value="initialMetric"
        :campaigns="campaigns"
        metric-id="creation"
        @updateForm="updateForm"
      />
    </template>
    <template #footer>
      <Button
        :loading="loading"
        @click="createMetric"
      >
        Create
      </Button>
      <Button
        :loading="loading"
        class="p-button-success ml-2"
        @click="toggleCalculator"
      >
        {{ isCalcOpen ? "Close Calculator" : "Use Calculator" }}
      </Button>
      <div
        v-show="isCalcOpen"
      >
        <Calculator
          metric-id="creation"
        />
      </div>
    </template>
  </Card>
  <Toast />
</template>
<script>
import Calculator from '@/components/utils/calculator/Calculator.vue';
import { provide, ref } from 'vue';

import { METRIC_CREATION_SUCCESS } from '../../../../constants/toastConstants';
import { validateMetricForm } from '../../../../utils/formUtils';
import MetricForm from './form/MetricForm.vue';
import A2CAClient from '../../../../api/a2caAuthorizedClient';
import { formMetricToApi } from '../../../../utils/metricUtils';

const METRIC_TEMPLATE = {
  name: '',
  campaigns: [],
  metrics: {
    // eslint-disable-next-line vue/no-unused-properties
    salesRoyaltiesPercentage: null,
    // eslint-disable-next-line vue/no-unused-properties
    acorGoal: 1,
    // eslint-disable-next-line vue/no-unused-properties
    averageKENPC: null,

    // eslint-disable-next-line vue/no-unused-properties
    minBid: null,
    // eslint-disable-next-line vue/no-unused-properties
    maxBid: null,
  },
  allCampaignsSelected: false,
};

export default {
  components: { Calculator, MetricForm },
  inject: ['query'],
  props: {
    selectedProfile: {
      type: Object,
      required: true,
    },
    campaigns: {
      type: Array,
      required: true,
    },
  },
  emits: ['createMetric'],
  setup() {
    const rm = ref(0);
    const nrr = ref(0);
    const averageKENPC = ref(0);

    const updateMetrics = (metrics) => {
      rm.value = metrics.rm;
      nrr.value = metrics.nrr;
      averageKENPC.value = metrics.averageKENPC;
    };
    const scrollRef = ref();
    provide('creation-calculator', {
      rm, nrr, averageKENPC, updateMetrics,
    });
    provide('scrollRef', scrollRef);

    return {
      scrollRef,
    };
  },
  data() {
    return {
      isCalcOpen: false,
      initialMetric: METRIC_TEMPLATE,
      currentMetric: METRIC_TEMPLATE,
      loading: false,
    };
  },
  methods: {

    toggleCalculator() {
      this.isCalcOpen = !this.isCalcOpen;
    },
    updateForm(updatedForm) {
      this.currentMetric = updatedForm;
    },
    async createMetric() {
      this.loading = true;
      if (!validateMetricForm(this, this.campaigns.length)) {
        return;
      }

      const metric = formMetricToApi(this.currentMetric, this.campaigns.length);

      const client = new A2CAClient(this.query);
      const createdMetric = await client.createMetric(
        this.selectedProfile.profileId,
        metric,
      );

      // Copy local storage calculator
      localStorage.setItem(`${createdMetric.id}--books-info`, localStorage.getItem('creation--books-info') ?? '{}');

      console.debug('Created metric', createdMetric);
      this.$emit('createMetric', createdMetric);

      this.$toast.add({
        severity: 'success',
        summary: METRIC_CREATION_SUCCESS.summary,
        detail: METRIC_CREATION_SUCCESS.detail,
        life: 3000,
      });

      const resettedMetric = { ...JSON.parse(JSON.stringify(METRIC_TEMPLATE)), editId: Math.random() };
      this.initialMetric = resettedMetric;

      this.loading = false;
    },
  },
};
</script>
