export const COUNTRY_CODES = {
  SPAIN: 'ESP',
  UNITED_KINGDOM: 'GBR',
  USA: 'USA',
  CANADA: 'CAN',
  POLAND: 'POL',
  GERMANY: 'GER',
  FRANCE: 'FRA',
  ITALY: 'ITA',
  BELGIUM: 'BEL',
  NETHERLANDS: 'NED',
};

export const BOOK_TYPE = {
  EBOOK: 'ebk',
  PAPERBACK_STANDARD: 'paper_std',
  PAPERBACK_EXTENDED: 'paper_ext',
};
