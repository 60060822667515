<template>
  <Card>
    <template #content>
      <i ref="scrollRef" />
      <MetricForm
        :metric-id="metricId"
        :editable="editing"
        :initial-value="initialMetric"
        :campaigns="campaigns"
        @updateForm="updateForm"
      />
    </template>
    <template #footer>
      <div class="flex flex-column">
        <div class="flex">
          <div class="flex">
            <Button
              v-show="!editing"
              :loading="loading"
              class="p-button-danger"
              @click="deleteMetric"
            >
              Delete
            </Button>

            <Button
              v-show="editing"
              :loading="loading"
              class="p-button-success"
              @click="toggleCalculator"
            >
              {{ isCalcOpen ? "Close Calculator" : "Open Calculator" }}
            </Button>
          </div>
          <div class="flex justify-content-end button-gap align-items-center flex-1">
            <Button
              v-show="!editing"
              :loading="loading"
              class="p-button-secondary"
              @click="startEdit"
            >
              Edit
            </Button>
            <Button
              v-show="editing"
              :loading="loading"
              class="p-button-secondary"
              @click="cancelEdit"
            >
              Cancel
            </Button>
            <Button
              v-show="editing"
              :loading="loading"
              @click="updateMetric"
            >
              Update
            </Button>
          </div>
        </div>
        <div
          v-show="isCalcOpen"
        >
          <Calculator :metric-id="metricId" />
        </div>
      </div>
    </template>
  </Card>
  <Toast />
</template>
<script>
import { provide, ref } from 'vue';
import {
  METRIC_DELETION_SUCCESS, METRIC_UPDATE_SUCCESS,
} from '../../../../../constants/toastConstants';
import { validateMetricForm } from '../../../../../utils/formUtils';
import MetricForm from '../form/MetricForm.vue';
import A2CAClient from '../../../../../api/a2caAuthorizedClient';
import { formMetricToApi } from '../../../../../utils/metricUtils';
import Calculator from '../../../../utils/calculator/Calculator.vue';

export default {
  components: { Calculator, MetricForm },
  inject: ['query'],
  props: {
    selectedProfile: {
      type: Object,
      required: true,
    },
    campaigns: {
      type: Array,
      required: true,
    },
    metric: {
      type: Object,
      required: true,
    },
    metricId: {
      type: String,
      required: true,
    },

  },
  emits: ['deleteMetric'],
  setup(props) {
    const rm = ref(0);
    const nrr = ref(0);
    const averageKENPC = ref(0);

    const updateMetrics = (metrics) => {
      rm.value = metrics.rm;
      nrr.value = metrics.nrr;
      averageKENPC.value = metrics.averageKENPC;
    };
    const scrollRef = ref();
    provide(`${props.metricId}-calculator`, {
      rm, nrr, averageKENPC, updateMetrics,
    });
    provide('scrollRef', scrollRef);

    return {
      scrollRef,
    };
  },
  data() {
    return {
      initialMetric: this.metric,
      currentMetric: JSON.parse(JSON.stringify(this.metric)),
      lastEditedMetric: JSON.parse(JSON.stringify(this.metric)),
      loading: false,
      editing: false,
      isCalcOpen: false,
    };
  },
  methods: {
    startEdit() {
      this.editing = true;
    },
    cancelEdit() {
      this.editing = false;
      this.isCalcOpen = false;
      this.initialMetric = { ...JSON.parse(JSON.stringify(this.lastEditedMetric)), editId: Math.random() };
    },
    updateForm(updatedForm) {
      this.currentMetric = updatedForm;
    },
    toggleCalculator() {
      this.isCalcOpen = !this.isCalcOpen;
    },
    async deleteMetric() {
      this.loading = true;
      const client = new A2CAClient(this.query);
      const deletedMetric = await client.deleteMetric(this.metricId);
      console.debug('Deleted metric', deletedMetric);
      this.$toast.add({
        severity: 'success',
        summary: METRIC_DELETION_SUCCESS.summary,
        detail: METRIC_DELETION_SUCCESS.detail,
        life: 3000,
      });
      this.loading = false;
      this.$emit('deleteMetric', this.metricId);
    },
    async updateMetric() {
      this.loading = true;
      if (!validateMetricForm(this, this.campaigns.length)) {
        return;
      }

      this.lastEditedMetric = JSON.parse(JSON.stringify(this.currentMetric));
      const metric = formMetricToApi(this.currentMetric, this.campaigns.length);

      const client = new A2CAClient(this.query);
      const metricToUpdate = { ...metric, id: this.metricId };
      const updatedMetric = await client.updateMetric(
        this.selectedProfile.profileId,
        metricToUpdate,
      );

      console.debug('Updated bid rule', updatedMetric);

      this.$toast.add({
        severity: 'success',
        summary: METRIC_UPDATE_SUCCESS.summary,
        detail: METRIC_UPDATE_SUCCESS.detail,
        life: 3000,
      });

      this.loading = false;
      this.editing = false;
      this.isCalcOpen = false;
    },
  },
};
</script>

<style scoped>
  .button-gap {
    gap: 1rem;
  }
</style>
