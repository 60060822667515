<template>
  <Dialog
    v-model:visible="displayMaximumBidDialog"
    header="Maximum Bid"
  >
    The Maximum Bid is the maximum bid you are willing to pay for a target. Bids will not go beyond this number in spite of the rules.
  </Dialog>
  <Dialog
    v-model:visible="displayMinimumBidDialog"
    header="Minimum Bid"
  >
    The Minimum Bid is the minimum bid you are willing to pay for a target. Bids will not go below this number in spite of the rules.
  </Dialog>
  <Dialog
    v-model:visible="displayAverageRoyaltiesDialog"
    header="Net Royalty Rate"
    :breakpoints="{'960px': '75vw', '640px': '100vw'}"
    :style="{width: '50vw'}"
  >
    The Net Royalty Rate is the percentage of royalties earned after delivery or printing costs. E.g. For a campaign advertising one ebook, priced at $2.99 (thus earning a 70% royalty), and with a delivery cost of $0.1, the NRR would be 68%. Calculation: ($2.99-0.1)*70%/$2.99 = 68%. If you advertise multiple books in the same campaign, you'll want to calculate an average NRR for these books. You can do so by using the green calculator button below. Check the following <a
      href="https://www.booksflyer.com/post/setting-your-metrics"
      target="_blank"
    >article</a> for more info.
  </Dialog>
  <Dialog
    v-model:visible="displayAcorGoal"
    header=" Revenue Multiplier"
    :breakpoints="{'960px': '75vw', '640px': '100vw'}"
    :style="{width: '50vw'}"
  >
    The RM helps us adapt the bid optimisation based on your advertising strategy. The default value (1) will aim to equal your ad costs to your immediate ad revenue. This is ideal for advertising all books in a series, or a standalone. A higher RM will adjust the bids considering the obtention of a higher revenue than the one shown on the dashboard. This is meant for those advertising book 1 only (you can use the calculator below), or those looking to advertise at a loss to boost short term visibility. Check the following <a
      href="https://www.booksflyer.com/post/setting-your-metrics"
      target="_blank"
    >link</a>  for more info.
  </Dialog>
  <Dialog
    v-model:visible="displayAvgKENPInfo"
    header="Average KENPC"
    :breakpoints="{'960px': '75vw', '640px': '100vw'}"
    :style="{width: '50vw'}"
  >
    <b>Only for those in Kindle Select</b>: introduce the Kindle Edition Normalized Page Count so that we may estimate the number of borrows from your pages read. For more info on how to find this number click <a
      href="https://www.kdpcommunity.com/s/question/0D52T00005HWTfESAX/how-many-kenp-is-my-book?language=en_US"
      target="_blank"
    >here</a>. If you are advertising only one book, just include the KENPC of this book. If more than one book and the KENPC varies a lot between books, include the KENPC per book in the calculator below.
  </Dialog>

  <div class="formgrid grid">
    <div class="field col">
      <label
        for="sales_royalties"
        class="font-bold block"
      >Net Royalty Rate
        <span
          v-tooltip="'Click for more information'"
          class="pi pi-info-circle"
          @click="displayAverageRoyaltiesDialog = true"
        /></label>
      <InputNumber
        id="sales_royalties"
        v-model="innerVModel.salesRoyaltiesPercentage"
        :use-grouping="false"
        suffix="%"
        :min="1"
        :max="100"
        class="w-3"
        locale="en-US"
        :disabled="!editable"
      />
    </div>
    <div class="field col">
      <label
        for="acor_goal"
        class="font-bold block"
      >Revenue Multiplier
        <span
          v-tooltip="'Click for more information'"
          class="pi pi-info-circle"
          @click="displayAcorGoal = true"
        /></label>
      <InputNumber
        id="acor_goal"
        v-model="innerVModel.acorGoal"
        :use-grouping="false"
        :min-fraction-digits="0"
        :max-fraction-digits="2"
        :min="0.1"
        class="w-3"
        locale="en-US"
        :disabled="!editable"
      />
    </div>
    <div class="field col">
      <label
        for="average_kenpc"
        class="font-bold block"
      >Average KENPC
        <span
          v-tooltip="'Click for more information'"
          class="pi pi-info-circle"
          @click="displayAvgKENPInfo = true"
        /></label>
      <InputNumber
        id="average_kenpc"
        v-model="innerVModel.averageKENPC"
        :use-grouping="false"
        :min-fraction-digits="0"
        :max-fraction-digits="2"
        :min="0"
        class="w-3"
        locale="en-US"
        :disabled="!editable"
      />
    </div>
    <div class="field col">
      <label
        for="maxBidInput"
        class="text-base font-semibold block"
      >
        Maximum bid
        <span
          v-tooltip="'Click for more information'"
          class="pi pi-info-circle"
          @click="displayMaximumBidDialog = true"
        />
      </label>
      <InputNumber
        id="maxBidInput"
        v-model="innerVModel.maxBid"
        :min="0.02"
        :min-fraction-digits="0"
        :max-fraction-digits="2"
        :use-grouping="false"
        class="w-3"
        :prefix="currencySymbol"
        locale="en-US"
        :allow-empty="true"
        :disabled="!editable"
      />
    </div>
    <div class="field col">
      <label
        for="minBidInput"
        class="text-base font-semibold block"
      >
        Minimum bid
        <span
          v-tooltip="'Click for more information'"
          class="pi pi-info-circle"
          @click="displayMinimumBidDialog = true"
        />
      </label>
      <InputNumber
        id="minBidInput"
        v-model="innerVModel.minBid"
        :min="0.02"
        :min-fraction-digits="0"
        :max-fraction-digits="2"
        :use-grouping="false"
        class="w-3"
        :prefix="currencySymbol"
        locale="en-US"
        :allow-empty="true"
        :disabled="!editable"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import {
  inject, watch, ref, toRef,
} from 'vue';
import { currencySymbolFromCode } from '../../../../../utils/formatUtils';

export default {
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
    editId: {
      type: Number,
      required: false,
      default: 0,
    },
    metricId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const innerVModel = toRef(props, 'modelValue');
    const displayMaximumBidDialog = ref(false);
    const displayMinimumBidDialog = ref(false);
    const displayAverageRoyaltiesDialog = ref(false);
    const displayAcorGoal = ref(false);
    const displayAvgKENPInfo = ref(false);

    const { rm, nrr, averageKENPC } = inject(`${props.metricId}-calculator`);

    watch([rm, nrr, averageKENPC], ([nrm, nnrr, nKENPC]) => {
      innerVModel.value.averageKENPC = +nKENPC;
      innerVModel.value.acorGoal = +nrm;
      innerVModel.value.salesRoyaltiesPercentage = +nnrr;
    });

    return {
      innerVModel,
      displayMaximumBidDialog,
      displayMinimumBidDialog,
      displayAverageRoyaltiesDialog,
      displayAcorGoal,
      displayAvgKENPInfo,
    };
  },
  computed: {
    currencySymbol() {
      return currencySymbolFromCode(this.selectedProfile.currencyCode);
    },
    ...mapGetters(['selectedProfile']),
  },
  watch: {
    editId: {
      handler(val, oldVal) {
        console.debug('Edit id changed');
        if (val !== oldVal) {
          console.debug('Edit id is different, clearing');
          this.salesRoyaltiesPercentage = this.modelValue.salesRoyaltiesPercentage;
          this.averageKENPC = this.modelValue.averageKENPC;
          this.acorGoal = this.modelValue.acorGoal;
          this.maxBid = this.modelValue.maxBid;
          this.minBid = this.modelValue.minBid;
        }
      },
    },
  },
};
</script>
