<template>
  <Card>
    <template #title>
      <h2 class="text-2xl">
        Metrics List
      </h2>
    </template>
    <template #content>
      <MetricListingTab
        :selected-profile="selectedProfile"
        :campaigns="campaigns"
        :metrics="metrics"
        @deleteMetric="deleteMetric"
      />
    </template>
  </Card>
  <Toast />
</template>
<script>

import MetricListingTab from './MetricListingTab.vue';

export default {
  components: { MetricListingTab },
  props: {
    selectedProfile: {
      type: Object,
      required: true,
    },
    campaigns: {
      type: Array,
      required: true,
    },
    metrics: {
      type: Array,
      required: true,
    },

  },
  emits: ['deleteMetric'],
  computed: {

  },
  methods: {
    deleteMetric(bidRuleId) {
      this.$emit('deleteMetric', bidRuleId);
    },
  },
};
</script>
