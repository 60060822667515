<template>
  <p v-if="metrics.length === 0">
    No data.
  </p>
  <template v-else>
    <div
      v-for="metric in metrics"
      :key="metric.id"
      class="pb-3"
    >
      <EditableMetric
        :selected-profile="selectedProfile"
        :campaigns="campaigns"
        :metric="formatApiMetric(metric)"
        :metric-id="metric.id"
        @deleteMetric="deleteMetric"
      />
    </div>
  </template>
</template>
<script>

import EditableMetric from '../edit/EditableMetric.vue';
import { apiMetricToForm } from '../../../../../utils/metricUtils';

export default {
  components: { EditableMetric },
  props: {
    selectedProfile: {
      type: Object,
      required: true,
    },
    campaigns: {
      type: Array,
      required: true,
    },
    metrics: {
      type: Array,
      required: true,
    },

  },
  emits: ['deleteMetric'],
  methods: {
    formatApiMetric(metric) {
      const campaignIds = this.campaigns.filter((campaign) => metric.campaignIds.includes(campaign.campaignId));

      return apiMetricToForm({ ...metric, campaignIds });
    },
    deleteMetric(metricId) {
      this.$emit('deleteMetric', metricId);
    },
  },
};
</script>
